@font-face {
    font-family: 'Manrope';
    src: url('../public/assets/fonts/Manrope/Manrope-VariableFont_wght.ttf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --colorT1: #db3b2b;
    --text-Color-T1: #4b5563;
    --text-Title-Color: #101827;
    --text-BannerColor: #4c4c4c;
    --borderRadiusBtnT1: .7rem;
    --gap-Sections: 4rem;
}

html {
    scroll-behavior: smooth;
    font-family: 'Manrope', sans-serif;
}

body {
    color: var(--text-Color-T1);
    font-family: 'Manrope', sans-serif;
}


h1, h2, h3, h4, h5, h6 {
    color: var(--text-Title-Color);
    font-family: 'Manrope', sans-serif;
}

.btnBorderT1 {
    color: var(--colorT1);
    border-radius: var(--borderRadiusBtnT1);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-color: var(--colorT1);
    border-width: 1px;
}

.btnBorderT1:hover {
    --tw-bg-opacity: 1;
    border-color: #b91c1c;
    color: #b91c1c;
}

.btnSolidT1 {
    background-color: var(--colorT1);
    color: white;
    border-radius: var(--borderRadiusBtnT1);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.btnSolidT1:hover {
    --tw-bg-opacity: 1;
    background-color: #b91c1c;
    border-color: #b91c1c;
    color: white;
}

.clip-banner {
  clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 0% 100%);
}

@media (max-width: 640px) {
    .clip-banner {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}